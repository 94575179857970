import { authConstants } from "./constants/authConstant";

const userLogin = (request: any) => {
  return { type: authConstants.userLoginRequest, input: request };
};

const forgotPassword = (request: any) => {
  return { type: authConstants.forgotPasswordRequest, input: request };
};

const resendEmail = (request: any) => {
  return { type: authConstants.resendMailRequest, input: request };
};

const forgotPasswordVerifyToken = (request: any) => {
  return { type: authConstants.forgotPasswordVerifyTokenRequest, input: request };
};

const renewToken = (request: any) => {
  return { type: authConstants.renewTokenRequest, input: request };
};

const resetPassword = (request: any) => {
  return { type: authConstants.resetPasswordRequest, input: request };
};

const updatePassword = (request: any) => {
  return { type: authConstants.updatePasswordRequest, input: request };
};

export const authActions = {
  userLogin,
  forgotPassword,
  forgotPasswordVerifyToken,
  resetPassword,
  updatePassword,
  resendEmail,
  renewToken
};
