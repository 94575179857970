import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Empty } from 'antd';

interface SentimentProps {
    chartHeight: number;
}

const ImageSentimentByStats: React.FC<SentimentProps> = ({ chartHeight }) => {

    const isByStats = useSelector((state: any) => state.isByStats);

    const [sentiment, setSentiment] = useState<any>({
        dates: [], happyData: [], sadData: [], angerData: [], fearData: [], surpriseData: [], neutralData: [], disgustedData: []
    });

    const noData = sentiment.happyData.every((val: number) => val === 0) &&
        sentiment.sadData.every((val: number) => val === 0) &&
        sentiment.angerData.every((val: number) => val === 0) &&
        sentiment.fearData.every((val: number) => val === 0) &&
        sentiment.surpriseData.every((val: number) => val === 0) &&
        sentiment.neutralData.every((val: number) => val === 0) &&
        sentiment.disgustedData.every((val: number) => val === 0);

    const options: Highcharts.Options = {
        chart: { type: "column", height: chartHeight, borderRadius: 12 },
        title: { text: "Statistics of Events", align: "left", margin: 25 },
        xAxis: { categories: sentiment.dates, title: { text: null } },
        yAxis: { min: 0, title: { text: null }, gridLineColor: '#eaeaea' },
        legend: { align: 'center', verticalAlign: 'bottom', layout: 'horizontal' },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
            shared: true,
        },
        plotOptions: {
            column: { stacking: 'normal', maxPointWidth: 50 },
        },
        credits: { enabled: false },
        series: [
            { name: "Happy", type: "column", data: sentiment.happyData || [], color: '#ffeb3b' },
            { name: "Sad", type: "column", data: sentiment.sadData || [], color: '#2196f3' },
            { name: "Angry", type: "column", data: sentiment.angerData || [], color: '#f44336' },
            { name: "Fearful", type: "column", data: sentiment.fearData || [], color: '#9c27b0' },
            { name: "Surprised", type: "column", data: sentiment.surpriseData || [], color: '#ff9800' },
            { name: "Neutral", type: "column", data: sentiment.neutralData || [], color: '#607d8b' },
            { name: "Disgusted", type: "column", data: sentiment.disgustedData || [], color: '#4caf50' }
        ],
    };

    useEffect(() => {
        if (isByStats.isSuccess) {
            const dates = isByStats.isByStats.map((item: any) => item.period);
            const happyData = isByStats.isByStats.map((item: any) => item.sentiments.happy);
            const sadData = isByStats.isByStats.map((item: any) => item.sentiments.sad);
            const angerData = isByStats.isByStats.map((item: any) => item.sentiments.angry);
            const fearData = isByStats.isByStats.map((item: any) => item.sentiments.fearful);
            const surpriseData = isByStats.isByStats.map((item: any) => item.sentiments.surprised);
            const neutralData = isByStats.isByStats.map((item: any) => item.sentiments.neutral);
            const disgustedData = isByStats.isByStats.map((item: any) => item.sentiments.disgusted);

            setSentiment({ dates, happyData, sadData, angerData, fearData, surpriseData, neutralData, disgustedData });
        } else if (isByStats.isError) {
            setSentiment({ dates: [], happyData: [], sadData: [], angerData: [], fearData: [], surpriseData: [], neutralData: [], disgustedData: [] });
        }
    }, [isByStats]);

    return (
        <div className="nodata">
            {noData ? (
                <div>
                    <div className='ch-title'>Statistics of Events</div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            ) : (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}
        </div>
    );
};

export default ImageSentimentByStats;