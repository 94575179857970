export const authConstants = {
  userLoginRequest: "USER_LOGIN_REQUEST",
  userLoginSuccess: "USER_LOGIN_SUCCESS",
  userLoginFailure: "USER_LOGIN_FAILURE",

  forgotPasswordRequest: "FORGOT_PASSWORD_REQUEST",
  forgotPasswordSuccess: "FORGOT_PASSWORD_SUCCESS",
  forgotPasswordFailure: "FORGOT_PASSWORD_FAILURE",

  resendMailRequest: "RESEND_MAIL_REQUEST",
  resendMailSuccess: "RESEND_MAIL_SUCCESS",
  resendMailFailure: "RESEND_MAIL_FAILURE",

  forgotPasswordVerifyTokenRequest: "FORGOT_PASSWORD_VERIFYTOKEN_REQUEST",
  forgotPasswordVerifyTokenSuccess: "FORGOT_PASSWORD_VERIFYTOKEN_SUCCESS",
  forgotPasswordVerifyTokenFailure: "FORGOT_PASSWORD_VERIFYTOKEN_FAILURE",

  resetPasswordRequest: "RESET_PASSWORD_REQUEST",
  resetPasswordSuccess: "RESET_PASSWORD_SUCCESS",
  resetPasswordFailure: "RESET_PASSWORD_FAILURE",

  updatePasswordRequest: "UPDATE_PASSWORD_REQUEST",
  updatePasswordSuccess: "UPDATE_PASSWORD_SUCCESS",
  updatePasswordFailure: "UPDATE_PASSWORD_FAILURE",

  renewTokenRequest : "RENEW_TOKEN_REQUEST",
  renewTokenSuccess : "RENEW_TOKEN_SUCCESS",
  renewTokenFailure : "RENEW_TOKEN_FAILURE",
};
