import { clubConstants } from "./constants/clubConstant";

const clubRegister = (request: any) => {
  return { type: clubConstants.clubRegisterRequest, input: request };
};

const clubCreate = (request: any) => {
  return { type: clubConstants.clubCreateRequest, input: request };
};

const clubVerifyOTP = (request: any) => {
  return { type: clubConstants.verifyOtpRequest, input: request };
};

const resendotpRequest = (request: any) => {
  return { type: clubConstants.resendOtpRequest, payload: request };
};

const getAllClubs = () => {
  return { type: clubConstants.getAllClubsRequest }
}

const clubGetByFilter = (request: any) => {
  return { type: clubConstants.clubGetByFilterRequest, payload: request }
}

const getClub = (request: any) => {
  return { type: clubConstants.getClubRequest, input: request };
};

const updateClub = (request: any) => {
  return { type: clubConstants.updateClubRequest, input: request };
};

const deleteClub = (request: any) => {
  return { type: clubConstants.deleteClubRequest, input: request };
};

const getClubDomain = (request: any) => {
  return { type: clubConstants.getClubByDomainRequest, input: request };
};

const joinMember = (request: any) => {
  return { type: clubConstants.memberJoiningRequest, input: request };
};

const resendEmail = (request: any) => {
  return { type: clubConstants.memberEmailResendRequest, input: request };
};

const resendWhatsApp = (request: any) => {
  return { type: clubConstants.memberWhatsAppResendRequest, input: request };
};

const verifyEmail = (request: any) => {
  return { type: clubConstants.memberEmailVerificationRequest, input: request };
};

const verifyPhone = (request: any) => {
  return { type: clubConstants.memberPhoneVerificationRequest, input: request };
};

export const clubActions = {
  clubRegister, resendotpRequest, clubVerifyOTP,
  clubCreate, getClub, updateClub, deleteClub,
  getAllClubs, clubGetByFilter,
  getClubDomain,
  joinMember, resendEmail, resendWhatsApp, verifyEmail, verifyPhone,
};
