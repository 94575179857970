import { call, put } from "redux-saga/effects";
import { clubConstants } from "../actions/constants/clubConstant";
import { clubService } from "../services/clubService";
import { commonConstants } from "../actions/constants/commonConstant";

function* clubRegister(request: any): any {
    try {
        const response = yield call(clubService.clubCreate, request.input);
        if (response.success) {
            yield put({ type: clubConstants.clubRegisterSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.clubRegisterFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.clubRegisterFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* clubCreate(request: any): any {
    try {
        const response = yield call(clubService.clubCreate, request.input);
        if (response.success) {
            yield put({ type: clubConstants.clubCreateSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: clubConstants.clubCreateFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.clubCreateFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* clubVerifyOTP(request: any): any {
    try {
        const response = yield call(clubService.clubVerifyOTP, request.input);
        if (response.success) {
            yield put({ type: clubConstants.verifyOtpSuccess, payload: response.message });
        }
        else {
            yield put({ type: clubConstants.verifyOtpFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.verifyOtpFailure, payload: error });
    }
}

function* otpResend(request: any): any {
    try {
        const response = yield call(clubService.resendOtp, request.payload);
        if (response.success) {
            yield put({ type: clubConstants.resendOtpSuccess, payload: response.message });
        }
        else {
            yield put({ type: clubConstants.resendOtpFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.resendOtpFailure, payload: error });
    }
}

function* getAllClubs(): any {
    try {
        const response = yield call(clubService.getAllClubs);
        if (response.success) {
            yield put({ type: clubConstants.getAllClubsSuccess, payload: response.resultObject.clubList });
        } else {
            yield put({ type: clubConstants.getAllClubsFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.getAllClubsFailure, payload: error });
    }
}

function* clubGetByFilter(request: any): any {
    try {
        const response = yield call(clubService.clubGetByFilter, request.payload);
        if (response.success) {
            yield put({ type: clubConstants.clubGetByFilterSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.clubGetByFilterFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.clubGetByFilterFailure, payload: error });
    }
}

function* getClub(request: any): any {
    try {
        const response = yield call(clubService.getClub, request.input);
        if (response.success) {
            yield put({ type: clubConstants.getClubSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.getClubFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.getClubFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* updateClub(request: any): any {
    try {
        const response = yield call(clubService.updateClub, request.input);
        if (response.success) {
            yield put({ type: clubConstants.updateClubSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: clubConstants.updateClubFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.updateClubFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* deleteClub(request: any): any {
    try {
        const response = yield call(clubService.deleteClub, request.input);
        if (response.success) {
            yield put({ type: clubConstants.deleteClubSuccess, payload: response.resultObject });
            yield put({ type: commonConstants.showSuccesMessage, payload: response.message });
        }
        else {
            yield put({ type: clubConstants.deleteClubFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.deleteClubFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* getClubByDomain(request: any): any {
    try {
        const response = yield call(clubService.getClubByDomain, request.input);
        if (response.success) {
            yield put({ type: clubConstants.getClubByDomainSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.getClubByDomainFailure, payload: response.errorMessage });
            yield put({ type: commonConstants.ShowErrorMessage, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.getClubByDomainFailure, payload: error });
        yield put({ type: commonConstants.ShowErrorMessage, payload: error });
    }
}

function* joinMember(request: any): any {
    try {
        const response = yield call(clubService.joinMember, request.input);
        if (response.success) {
            yield put({ type: clubConstants.memberJoiningSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.memberJoiningFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.memberJoiningFailure, payload: error });
    }
}

function* resendEmail(request: any): any {
    try {
        const response = yield call(clubService.resendEmail, request.input);
        if (response.success) {
            yield put({ type: clubConstants.memberEmailResendSuccess, payload: response.message });
        }
        else {            
            yield put({ type: clubConstants.memberEmailResendFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.memberEmailResendFailure, payload: error });
    }
}

function* verifyEmail(request: any): any {
    try {
        const response = yield call(clubService.verifyEmail, request.input);
        if (response.success) {
            yield put({ type: clubConstants.memberEmailVerificationSuccess, payload: response.resultObject });
        }
        else {
            yield put({ type: clubConstants.memberEmailVerificationFailure, payload: response.errorMessage });
        }
    } catch (error) {
        yield put({ type: clubConstants.memberEmailVerificationFailure, payload: error });
    }
}

export const clubSaga = {
    clubRegister, clubVerifyOTP, otpResend,
    clubCreate, getClub, updateClub, deleteClub,
    getAllClubs, clubGetByFilter, getClubByDomain,
joinMember, resendEmail, verifyEmail
}
