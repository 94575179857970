import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Empty, Progress } from 'antd';
import { FaArrowAltCircleUp, FaArrowAltCircleDown, FaMinusCircle } from "react-icons/fa";

interface SentimentData {
  emotion: string;
  count: number;
  percentage: number;
  change: number;
  isIncrease: boolean;
  isDecrease :boolean;
  isNoChanges :boolean;
  color: string;
}
const ImageSentimentByMonth: React.FC = () => {

  const isByMonth = useSelector((state: any) => state.isByMonth);

  const [sentiment, setSentiment] = useState<SentimentData[]>([])

  useEffect(() => {
    if (isByMonth.isSuccess) {
      const order = ["Happy", "Neutral", "Sad"];
      const colorMap: { [key: string]: string } = { Happy: '#0057FF', Neutral: '#F5A623', Sad: '#D0021B', };

      const mappedSentiment = isByMonth.isByMonth.map((item: any) => ({
        emotion: item.status.charAt(0).toUpperCase() + item.status.slice(1),
        count: item.count,
        percentage: Math.round(item.percentage),
        change: Math.round(item.change),
        isIncrease: item.isIncreased,
        isDecrease: item.isDecreased,
        isNoChanges :item.isNoChanges,
        color: colorMap[item.status.charAt(0).toUpperCase() + item.status.slice(1)] || '#000000',
      })).sort((a: any, b: any) => order.indexOf(a.emotion) - order.indexOf(b.emotion));

      setSentiment(mappedSentiment);
    } else if (isByMonth.isError) {
      setSentiment([]);
    }
  }, [isByMonth]);

  return (
    <div className={`img-emotion-container ${sentiment.length < 0 ? "nodata-available":""}`}>
      {sentiment.length > 0 ?
        sentiment.map((card, index) => (
          <div className="sentiment-card" key={index}>
            <div className="sc-left">
              <h3>{card.emotion}</h3>
              <div className="ecount"><strong>{card.count}</strong> Images</div>
              <div className="change-status" style={{ color: card.color }}>
                <div className={`status-icon ${card.isNoChanges? 'nochange':""}`}>
                  {card.isIncrease ? <FaArrowAltCircleUp size={18} /> : card.isDecrease? <FaArrowAltCircleDown size={18} />:<FaMinusCircle size={18} />}
                </div>
                <div className="status-text">
                  {card.change}% {card.isIncrease ? 'Inc' : card.isDecrease ?'Dec': "-"}
                </div>
              </div>
            </div>
            <div className="sc-right">
              <Progress type="circle" percent={card.percentage} strokeColor={card.color} strokeWidth={8} />
            </div>
          </div>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </div>
  );
};

export default ImageSentimentByMonth;