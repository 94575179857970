import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllRSVP } from '../../actions/eventAction';
import { Grid } from "antd";

const Rsvp: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const rsvpResponse = useSelector((state: any) => state.getAllRSVPresponse);
    const [eventData, setEventData] = useState<any>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        const eventId = queryParams.get('eventId');
        const email = queryParams.get('email');

        if (status && eventId && email) {
            dispatch(getAllRSVP({ status, eventId, email }));
        }
    }, [location.search, dispatch]);

    useEffect(() => {
        if (rsvpResponse && rsvpResponse.isSuccess) {
            setEventData(rsvpResponse.rsvpResponse);
        }
    }, [rsvpResponse]);

    return (
        <div>
            <h1 data-testid="headerResponse"style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Thanks For Your Response</h1>

            {eventData ? (
                <div className="rsvp-container" style={{ display: "grid", gap: "20px", justifyItems: "center" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "71px", width: "100%" }}>
                        <p data-testid="eventInp"><b data-testid="lblEvent">Event Name:</b> {eventData.eventName}</p>
                        <p data-testid="clubInp"><b data-testid="lblClub">Club Name:</b> {eventData.clubName}</p>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "71px", width: "100%" }}>
                        <p data-testid="descInp"><b data-testid="lblDesc">Description:</b> {eventData.description}</p>
                        <p data-testid="venueInp"><b data-testid="lblVenue">Venue:</b> {eventData.venue}</p>
                    </div>
                    <img data-testid="impInp" src={eventData.eventBanner} alt={eventData.eventName} style={{ maxWidth: '100%', height: 'auto', gridColumn: "1 / -1" ,marginRight: "35px"}} />
                </div>
            ) : (
                <p>No event data available</p>
            )}
        </div>
    );
};
export default Rsvp;