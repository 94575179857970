import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Select, SelectProps, Spin } from "antd";
import TextSentimentByMonth from "./textSentimentByMonth"
import TextSentimentByStats from "./textSentimentByStats";
import TextSentimentByCategory from "./textSentimentByCategory";
import { eventActions } from "../../actions/eventAction";
import { common } from "../../actions/constants/common";
import ImageSentimentByMonth from "./imageSentimentByMonth";
import ImageSentimentByStats from "./imageSentimentByStats";
import ImageSentimentByCategory from "./ImageSentimentByCategory";

const chartKeys = Object.freeze({
    textStats: "textStatsValue",
    textCategory: "textCategoryValue",
    imageStats: 'imageStatsValue',
    imageCategory: 'ImageCategoryValue'
});

const options: SelectProps['options'] = [{ value: 'weekly', label: 'Week' },
{ value: 'monthly', label: 'Month' }, { value: 'quarterly', label: 'Quarter' }];

const Sentiment = () => {

    const dispatch = useDispatch();
    const { state } = useLocation();

    const auth = useSelector((state: any) => state.authenticate);
    const tsByMonth = useSelector((state: any) => state.tsByMonth);
    const tsByCategory = useSelector((state: any) => state.tsByCategory);
    const tsByStats = useSelector((state: any) => state.tsByStats);
    const isByMonth = useSelector((state: any) => state.isByMonth);
    const isByCategory = useSelector((state: any) => state.isByCategory);
    const isByStats = useSelector((state: any) => state.isByStats);

    const { role, clubId: userClubId } = auth.authData;
    const { clubId: stateClubId, id: stateId } = state || {};
    const id = role === common.admin
        ? (stateClubId || stateId || "")
        : (!stateClubId && !stateId ? userClubId : stateClubId || stateId);

    const isEvent = Boolean(stateId);
    const isClub = Boolean(stateClubId ? stateClubId : !isEvent ? userClubId : "");
    const isAll = role === common.admin && !isClub && !isEvent;

    const [chartValue, setChartValue] = useState({
        textStatsValue: 'quarterly', textCategoryValue: 'quarterly',
        imageStatsValue: 'quarterly', ImageCategoryValue: 'quarterly'
    });

    const [chartHeight, setChartHeight] = useState(0)

    const handleResize = () => {
        if (window.innerWidth <= 575 && window.innerWidth > 100)
            setChartHeight(250)
        else if (window.innerWidth <= 768 && window.innerWidth > 575)
            setChartHeight(275)
        else if (window.innerWidth <= 1024 && window.innerWidth > 768)
            setChartHeight(300)
        else if (window.innerWidth <= 1440 && window.innerWidth > 1024)
            setChartHeight(300)
        else
            setChartHeight(300)
    };

    const handleOnSelectChange = (key: string) => (value: string) => {
        setChartValue({ ...chartValue, [key]: value });
        if (key === chartKeys.textStats)
            dispatch(eventActions.getTextSentimentByStats({ id, isEvent, isClub, isAll, period: value }))
        else if (key === chartKeys.textCategory)
            dispatch(eventActions.getTextSentimentByCategory({ id, isEvent, isClub, isAll, category: value }))
        else if (key === chartKeys.imageStats)
            dispatch(eventActions.getImageSentimentByStats({ id, isEvent, isClub, isAll, period: value }));
        else if (key === chartKeys.imageCategory)
            dispatch(eventActions.getImageSentimentByCategory({ id, isEvent, isClub, isAll, category: value }));
    };

    useEffect(() => {
        dispatch(eventActions.getTextSentimentByMonth({ id, isEvent, isClub, isAll }));
        dispatch(eventActions.getTextSentimentByStats({ id, isEvent, isClub, isAll, period: chartValue.textStatsValue }));
        dispatch(eventActions.getTextSentimentByCategory({ id, isEvent, isClub, isAll, category: chartValue.textCategoryValue }));
        dispatch(eventActions.getImageSentimentByMonth({ id, isEvent, isClub, isAll }));
        dispatch(eventActions.getImageSentimentByStats({ id, isEvent, isClub, isAll, period: chartValue.imageStatsValue }));
        dispatch(eventActions.getImageSentimentByCategory({ id, isEvent, isClub, isAll, category: chartValue.ImageCategoryValue }));
    }, [state]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div className="analytic-page">
            <div className="sentiment">
                <h3 data-testid="sectionTitle" className="sectionTitle">Image Sentiment</h3>
                <div className="st-disclaimer">
                    This analytics dashboard utilizes AI-driven technology to analyze data based on emotional insights.
                    Please be aware that the results are intended to provide guidance and may not encompass all perspectives or nuances.
                </div>
                <div className="is-top">
                    {isByMonth && isByMonth.isLoading ?
                        <div className='img-emotion-spin'><div className={isByMonth.isLoading ? 'inloader' : ""}><Spin size="small" /></div></div>
                        : <ImageSentimentByMonth />}
                </div>
                <div className="is-bottom">
                    <div className="is-stats-chart pos-relative" style={{ height: chartHeight + 100 }}>
                        <div className="chart-control">
                            <Select defaultValue={chartValue[chartKeys.imageStats]} onChange={handleOnSelectChange(chartKeys.imageStats)} options={options} />
                        </div>
                        {isByStats && isByStats.isLoading ?
                            <div className={isByStats.isLoading ? 'inloader' : ""}><Spin size="small" /></div>
                            : <ImageSentimentByStats chartHeight={chartHeight + 100} />}
                    </div>
                    <div className="is-category-chart pos-relative" style={{ height: chartHeight + 100 }}>
                        <div className="chart-control">
                            <Select defaultValue={chartValue[chartKeys.imageCategory]} onChange={handleOnSelectChange(chartKeys.imageCategory)} options={options} />
                        </div>
                        {isByCategory && isByCategory.isLoading ?
                            <div className={isByCategory.isLoading ? 'inloader' : ""}><Spin size="small" /></div>
                            : <ImageSentimentByCategory />}
                    </div>
                </div>
            </div>
            <div className="sentiment">
                <h3 data-testid="sectionTitle" className="sectionTitle">Text Sentiment</h3>
                <div className="st-disclaimer">
                    This text analysis tool leverages AI-driven technology to analyze data through the lens of affective insights.
                    The results are intended to provide guidance and may not encompass all perspectives or nuances.
                </div>
                <div className="ts-top">
                    <div className="tst-con month-chart" style={{ height: chartHeight }}>
                        {tsByMonth && tsByMonth.isLoading ?
                            <div className={tsByMonth.isLoading ? 'inloader' : ""}><Spin size="small" /></div>
                            : <TextSentimentByMonth chartHeight={chartHeight} />}
                    </div>
                    <div className="tst-con pos-relative category-chart" style={{ height: chartHeight }}>
                        <div className="chart-control">
                            <Select defaultValue={chartValue[chartKeys.textCategory]} onChange={handleOnSelectChange(chartKeys.textCategory)} options={options} />
                        </div>
                        {tsByCategory && tsByCategory.isLoading ?
                            <div className={tsByCategory.isLoading ? 'inloader' : ""}><Spin size="small" /></div>
                            : <TextSentimentByCategory />}
                    </div>
                </div>
                <div className="ts-bottom stat-chart" style={{ height: chartHeight }}>
                    <div className="chart-control">
                        <Select defaultValue={chartValue[chartKeys.textStats]} onChange={handleOnSelectChange(chartKeys.textStats)} options={options} />
                    </div>
                    {tsByStats && tsByStats.isLoading ?
                        <div className={tsByStats.isLoading ? 'inloader' : ""}><Spin size="small" /></div>
                        : <TextSentimentByStats chartHeight={chartHeight} />}
                </div>
            </div>
        </div>
    )
}

export default Sentiment