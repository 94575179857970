import { common } from "../actions/constants/common";
import { axiosBase, errorHandler } from "./axios";

const getAllUsers = () => {
    return axiosBase.get('/user')
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
}

const getClubManagers = (input: any) => {
    return axiosBase.post("/users/getclubmanagers", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const createUser = (input: any) => {
    return axiosBase.post("/users/create", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getRoles = (input: any) => {
    return axiosBase.get("/users/getroles", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getByFilter = (input: any) => {
    return axiosBase.post("/users/getbyfilter", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getUser = (input: any) => {
    return axiosBase.get("/users/getuser/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const updateUser = (input: any) => {
    return axiosBase.patch("/users/updateuser", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const deleteUser = (input: any) => {
    return axiosBase.delete("/users/deleteuser/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getProfile = (input: any) => {
    return axiosBase.get("/users/profile", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getUserNotification = (input: any) => {
    if(input === common.admin){
        return  axiosBase.get("/notification/productAdminNotification") 
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
    } else if(input === common.clubOwner) {
        return  axiosBase.get("/notification/clubAdminNotification") 
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
    } else {
        return  axiosBase.get("/notification/clubManagerNotification") 
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
    }
};

const getNotificationCount = () => {
    return axiosBase.get("/notification/getCount")
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const categoryChange = (input: any)=>{
    return axiosBase.post("/notification/changeCategory", input)
    .then((response) => response.data)
    .catch((error) => errorHandler(error));
}

export const userService = {
    getAllUsers,
    getClubManagers,
    createUser,
    getRoles,
    getByFilter,
    getUser,
    updateUser,
    deleteUser,
    getProfile,
    getUserNotification,
    getNotificationCount,
    categoryChange
}