import { useEffect, useState } from 'react';
import { Progress, Empty } from 'antd';
import { useSelector } from 'react-redux';

interface SentimentCategory {
    name: string;
    color: string;
    percent: number;
    emoji: string;
}

const ImageSentimentByCategory: React.FC = () => {

    const isByCategory = useSelector((state: any) => state.isByCategory);

    const [sentiment, setSentiment] = useState<SentimentCategory[]>([]);

    const order = [
        { label: 'Happy', color: '#ffeb3b', emoji: '😊' },
        { label: 'Sad', color: '#2196f3', emoji: '😢' },
        { label: 'Angry', color: '#f44336', emoji: '😠' },
        { label: 'Fearful', color: '#9c27b0', emoji: '😨' },
        { label: 'Surprised', color: '#ff9800', emoji: '😲' },
        { label: 'Neutral', color: '#607d8b', emoji: '😐' },
        { label: 'Disgusted', color: '#4caf50', emoji: '🤮' },
    ];

    useEffect(() => {
        if (isByCategory.isSuccess && isByCategory?.isByCategory?.length > 0) {
            const sentimentData = order.map((category) => {
                const item = isByCategory.isByCategory.find((item: any) => item.status.toLowerCase() === category.label.toLowerCase());
                return {
                    name: category.label,
                    percent: item ? item.percentage: 0,
                    color: category.color,
                    emoji: category.emoji
                };
            });
            setSentiment(sentimentData);
        } else if (isByCategory.isError) {
            setSentiment([]);
        }
    }, [isByCategory]);

    return (
        <div className='chart-container'>
            <div className='ch-title'>Categories Wise Stats</div>
            <div className='category-list'>
                {sentiment.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    : sentiment.map((category, index) => (
                        <div className='category-item' key={index}>
                            <div className='category-label'>{category.emoji} {category.name}</div>
                            <Progress status="active" percent={category.percent} showInfo={false}
                                strokeColor={category.color} className='progress-bar' />
                            <span className='percent'>{category.percent}%</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ImageSentimentByCategory;
