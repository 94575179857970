import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { authActions } from '../actions/authAction';
import { useDispatch } from 'react-redux';
import { getCurrentTime, getPromptBeforeIdle, getRenewalTimeBeforeExpiry, getTimeOut } from '../services/helper';

const IdleTimer: React.FC = () => {

  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.authenticate);
  const renewToken = useSelector((state: any) => state.renewToken);

  const [open, setOpen] = useState<boolean>(false);
  const [logoutCountdown, setLogoutCountdown] = useState<number>(getPromptBeforeIdle() / 1000);
  const [sessionExpiry, setSessionExpiry] = useState(auth?.authData?.tokenExpery)
  const [timeout, setTimeout] = useState(getTimeOut(auth?.authData?.tokenExpery))
  const [renewalTime, setRenewalTime] = useState<number>(getRenewalTimeBeforeExpiry(auth?.authData?.tokenExpery))
  const [promptBeforeIdle, setPromptBeforeIdle] = useState<number>(getPromptBeforeIdle())

  const onPrompt = () => {
    setOpen(true);
  };

  const onAction = () => {
    const currentTime = getCurrentTime()
    if ((currentTime > renewalTime && currentTime < sessionExpiry) && !open) {
      dispatch(authActions.renewToken({ token: auth?.authData?.token, isMobile: false }))
      reset();
    } else if (currentTime >= sessionExpiry) {
      handleLogout();
    } else { }
  };

  const { reset, activate } = useIdleTimer({
    onAction,
    onPrompt,
    timeout,
    promptBeforeIdle,
    debounce: 500,
  });

  const handleStay = () => {
    dispatch(authActions.renewToken({ token: auth?.authData?.token, isMobile: false }))
    setOpen(false);
    activate()
  };

  const handleLogout = () => {
    setOpen(false);
    localStorage.removeItem('AUTHDATA');
    window.location.href = '/login';
  };

  if (renewToken && renewToken.isSuccess && renewToken.isTokenRenewed) {
    setOpen(false)
    renewToken.isTokenRenewed = false
  }

  useEffect(() => {
    setSessionExpiry(auth?.authData?.tokenExpery)
    setTimeout(getTimeOut(auth?.authData?.tokenExpery))
    setRenewalTime(getRenewalTimeBeforeExpiry(auth?.authData?.tokenExpery))
    setPromptBeforeIdle(getPromptBeforeIdle())
    setLogoutCountdown(getPromptBeforeIdle() / 1000)
  }, [auth]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (open) {
        setLogoutCountdown((prev) => {
          if (prev <= 1) {
            handleLogout();
            return 0;
          }
          return prev - 1;
        });
      }
    }, 1000);

    const resetIdleTimer = () => { reset(); };
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('keypress', resetIdleTimer);
    window.addEventListener('focus', resetIdleTimer);

    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keypress', resetIdleTimer);
      window.removeEventListener('focus', resetIdleTimer);
    };
  }, [open, reset])

  return (
    <Modal title="Your session is about to expire!" centered closable={false} maskClosable={false} open={open}
      okText="Stay" cancelText="Logout" onOk={handleStay} onCancel={handleLogout}>
      <div><p>Auto logout in {logoutCountdown} seconds</p></div>
    </Modal>
  );
};

export default IdleTimer;



