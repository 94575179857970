import env from "./env"

export const getAccessToken = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth).token
    }
}

export const getUserRole = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth).role
    }
}
export const getAuthUser = () => {
    const auth = localStorage.getItem("AUTHDATA")
    if (auth) {
        return JSON.parse(auth)
    }
}

export const isAtLeast18YearsOld = (dateString:any) => {
    if (!dateString) return false;
    const today = new Date();
    const birthDate = new Date(dateString);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    return (age > 18 || (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0))));
  };

export const Capitalize = (char: any) => {
    if (typeof char !== 'string') return ''
    return char.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
}

export const isInt32 = (value: any) => {
    return Number.isInteger(value) &&
        value >= -2147483648 &&
        value <= 2147483647;
}

export const toInt32 = (value: any) => {
    if (value > 2147483647) {
        value = value % 2147483648;
    }
    if (value < -2147483648) {
        value = value + 4294967296;
    }
    return value | 0;
}

export const getTime = (value: any) => {
    const date = new Date(value);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const time = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    return time;
}

export const getTimeOut = (value: any) => {
    const timeout = sessionStorage.getItem("timeout")
    const newTime = (value - Math.floor(Date.now() / 1000)) * 1000
    const time = timeout ? timeout : isInt32(newTime) ? newTime : toInt32(newTime)
    return Number(time);
}

export const getRenewalTimeBeforeExpiry = (value: any) => {
    const renewal = sessionStorage.getItem("isTestRenewal")
    const time = renewal ? renewal : (Number(value) - (Number(env.renewalTimeBeforeExpiry) * 60))
    return Number(time);
}

export const getPromptBeforeIdle = () => {
    const prompt = sessionStorage.getItem("isTestPrompt")
    const newTime = env.promptBeforeIdle * 60 * 1000
    const time = prompt ? prompt : isInt32(newTime) ? newTime : toInt32(newTime)
    return Number(time);
}

export const getCurrentTime = () => {
    const sese = sessionStorage.getItem("currentTime")
    const time = sese ? sese : Math.floor(Date.now() / 1000)
    return Number(time);
}

export const getIsValidSession = (auth: any) => {
    const user = localStorage.getItem("AUTHDATA")
    const currentTime = getCurrentTime()
    if (user) {
        const authUser = JSON.parse(user)
        if (authUser) {
            if (currentTime < authUser?.tokenExpery) {
                return authUser
            } else if (currentTime >= authUser?.tokenExpery) {
                return null
            }
        }
    }
}

export const getIsTest = () => {
    const isTest = sessionStorage.getItem("isTest")
    if (isTest) return true
    else return false
}