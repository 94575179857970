import { useEffect, useState } from 'react';
import { Progress, Empty } from 'antd';
import { useSelector } from 'react-redux';

interface SentimentCategory {
    name: string;
    color: string;
    percent: number;
}

const TextSentimentByCategory: React.FC = () => {

    const tsByCategory = useSelector((state: any) => state.tsByCategory);

    const [sentiment, setSentiment] = useState<SentimentCategory[]>([]);

    const order = [
        { label: 'Positive', color: '#1890ff' },
        { label: 'Negative', color: '#f5222d' },
        { label: 'Neutral', color: '#8c8c8c' },
        { label: 'Mixed', color: '#faad14' },
    ];

    useEffect(() => {
        if (tsByCategory.isSuccess&& tsByCategory?.tsByCategory?.length>0) {
            const sentimentData = order.map((category) => {
                const item = tsByCategory.tsByCategory.find((item: any) => item.status.toLowerCase() === category.label.toLowerCase());
                return {
                    name: category.label,
                    percent: item ? item.percentage : 0,
                    color: category.color,
                };
            });
            setSentiment(sentimentData);
        } else if (tsByCategory.isError) {
            setSentiment([])
        }
    }, [tsByCategory]);

    return (
        <div className='chart-container'>
            <div className='ch-title'>Categories wise stats</div>
            <div className='category-list'>
                {sentiment.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    : sentiment.map((category, index) => (
                        <div className='category-item' key={index}>
                            <div className='category-label'>
                                <span className='dot' style={{ backgroundColor: category.color }}></span>
                                {category.name}
                            </div>
                            <Progress status="active" percent={category.percent} showInfo={false}
                                strokeColor={category.color} className='progress-bar' />
                            <span className='percent'>{category.percent}%</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default TextSentimentByCategory;
