import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Form, Input } from "antd";
import OtpInput from "react18-input-otp";
import { MdCardMembership } from "react-icons/md";
import { clubActions } from "../../actions/clubAction";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import GoogleIcon from "../../components/svg/googleLogin";
import FaceBookIcon from "../../components/svg/facebookLogin";
import env from "../../services/env";

const JoinClub = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const join = useSelector((state: any) => state.joinMember);
    const verifyEmail = useSelector((state: any) => state.verifyEmail);
    const resendEmail = useSelector((state: any) => state.resendEmail);

    const host = window.location.host.split(".");
    const [member, setMember] = useState({
        email: "",
        firstName: "",
        lastName: "",
        clubDomain: host.length > 1 ? host[0] : '',
        contactNumber: "",
        isSocialSigin: false
    });
    const [verifyOTP, setVerifyOTP] = useState({ email: "", otp: "" });
    const [isOTPSend, setIsOTPSend] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setMember({ ...member, [name]: value });
    };

    const handleOTPChange = (otp: any) => {
        setVerifyOTP({ ...verifyOTP, email: member.email, otp: otp })
    };

    const handleOnBack = () => {
        if (isOTPSend)
            setIsOTPSend(false)
        else
            navigate(-1)
    }

    const handleResendOtp = () => {
        dispatch(clubActions.resendEmail({ email: member.email }))
    }

    const handleSubmit = () => {
        if (isOTPSend)
            dispatch(clubActions.verifyEmail(verifyOTP))
        else
            dispatch(clubActions.joinMember(member))
    }

    if (join && join.isSuccess && join.isJoined) {
        join.isJoined = false;
        setIsOTPSend(true)
    }

    if (verifyEmail && verifyEmail.isSuccess && verifyEmail.isEmailVerified) {
        verifyEmail.isEmailVerified = false;
        setIsEmailVerified(true)
    }

    return (
        <div>
            {!isOTPSend && !isEmailVerified &&
                <div className='create-page'>
                    <div className='page-title' data-testid="pageTitle"> <MdCardMembership />Join as a member</div>
                    <div className="form-wrapper">
                        <Form form={form} onFinish={handleSubmit} initialValues={member}>
                            {join && join.isError && <div className="error-text" data-testid="error-text"> {join.error}</div>}
                            <Form.Item label="Fist Name" name="firstName" data-testid="firstNameLabel"
                                rules={[{ required: true, message: "First name is required!" }]}>
                                <Input data-testid="firstNameInput" name="firstName" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="Last Name" name="lastName" data-testid="lastNameLabel">
                                <Input data-testid="lastNameInput" name="lastName" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="Email" name="email" data-testid="emailLabel"
                                rules={[{ required: true, message: "Email is required!" },
                                { type: "email", message: "The input is not valid E-mail!" }]}>
                                <Input name="email" data-testid="emailInput" onChange={handleOnChange} />
                            </Form.Item>
                            <Form.Item label="WhatsApp Number" name="contactNumber" data-testid="whatsAppLabel"
                                rules={[{ required: false, message: "WhatsApp number is required!" },
                                { message: "The input is not valid number", pattern: new RegExp(/^[0-9]+$/) }]} >
                                <Input name="contactNumber" data-testid="whatsAppInput" onChange={handleOnChange} maxLength={10} minLength={10} />
                            </Form.Item>

                            <div className='button-groups'>
                                <Button type="primary" htmlType="button" data-testid="btnCancel" onClick={handleOnBack}> Cancel</Button>
                                <Button type="primary" htmlType="submit" data-testid="btnSubmit"> Join</Button>
                            </div>
                        </Form>
                        <Divider plain style={{ color: "gray" }} data-testid="txtValue">or continue with</Divider>
                        <div className="icon-container">
                            <LoginSocialGoogle
                                client_id={env.googleClientId}
                                scope="email"
                                onResolve={({ provider, data }) => {
                                    console.log('provider', provider, 'data', data)
                                    if (data) {
                                        setMember({
                                            ...member,
                                            email: data.email,
                                            firstName: data.given_name,
                                            lastName: data.family_name,
                                            isSocialSigin: true
                                        });
                                    }
                                }}
                                onReject={(err) => {
                                    console.log('err', err)
                                }}
                            >
                                <GoogleIcon style={{ marginLeft: "2px" }} data-testid="googleIcon" height={30} width={30} />
                            </LoginSocialGoogle>
                            <LoginSocialFacebook
                                appId={env.facebookAppId}
                                onResolve={({ provider, data }) => {
                                    console.log('provider', provider, 'data', data)
                                    if (data) {
                                        setMember({
                                            ...member,
                                            email: data.email,
                                            firstName: data.first_name,
                                            lastName: data.last_name,
                                            isSocialSigin: true
                                        });
                                    }
                                }}
                                onReject={(err) => {
                                    console.log('err', err)
                                }}
                            >
                                <FaceBookIcon style={{ marginLeft: "47px" }} data-testid="facebookIcon" height={28} width={30} />
                            </LoginSocialFacebook>

                        </div>
                    </div>
                </div>}
            {isOTPSend && !isEmailVerified &&
                <div className="create-page otp-con">
                    <div className='page-title' data-testid="pageTitle"> Confirm your email address and phone number</div>
                    <div className="form-wrapper">
                        <div data-testid="emailText" className="otp-text">
                            Please enter the 4 digit verification code that was sent to your mail. The code is valid for 5 minutes only
                        </div>
                        <Form className="otp-form" onFinish={handleSubmit}>
                            {resendEmail && resendEmail.isResendEmail && <div className="resend-text otp" data-testid="resend-text"> {resendEmail.resendEmail}</div>}
                            {resendEmail && resendEmail.isError && <div className="error-text otp" data-testid="error-text"> {resendEmail.error}</div>}
                            {verifyEmail && verifyEmail.isError && <div className="error-text otp" data-testid="error-text"> {verifyEmail.error}</div>}
                            <div className="verify-otp">
                                <Button data-testid="resendMail" onClick={handleResendOtp}> Send the email code again </Button>
                                <Button data-testid="changeMail" onClick={handleOnBack}>Change email address</Button>
                            </div>
                            <div className="verify-otp-con">
                                <OtpInput data-testid="otpEmailInput" value={verifyOTP.otp} onChange={handleOTPChange} numInputs={4}
                                    isInputNum={true} isSuccessed={false} errorStyle="error" successStyle="success"
                                    separator={<span></span>} separateAfter={3} shouldAutoFocus
                                    inputStyle={{ width: "50px", marginRight: "10px", height: "30px", }} />
                                <Button data-testid="verify-email" className="verify-btn" type="primary" htmlType="submit"> Verify Email</Button>
                            </div>
                        </Form>
                    </div>
                </div>}
            {isOTPSend && isEmailVerified &&
                <div className="member-verified">
                    <h3 data-testid="success-mes"> Thank you for joining us!</h3>
                    <div data-testid="success-mes1"> We will be sending you notifications regarding upcoming events.</div>
                    <Button data-testid="proceed-home" className="proceed-home" type="primary" onClick={() => navigate('/')} >Home</Button>
                </div>}
        </div>

    )
}

export default JoinClub;