import { axiosBase, errorHandler } from "./axios"

const getAllEvents = (input: any) => {
    return axiosBase.post('/events/getbyfilter', input)
        .then((response) => response.data)
        .catch(error => errorHandler(error))
}

const createEvent = (input: any) => {
    return axiosBase.post("/events/create", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getEvent = (input: any) => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return axiosBase.get("/events/getevent/" + input + "?timezone=" + browserTimeZone)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const updateEvent = (input: any) => {
    return axiosBase.patch("/events/updateevent", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const deleteEvent = (input: any) => {
    return axiosBase.delete("/events/deleteevent/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getUploadedImages = (input: any) => {
    return axiosBase.post("/gallery/getuploadedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getApprovedImages = (input: any) => {
    return axiosBase.post("/gallery/getapprovedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getRejectedImages = (input: any) => {
    return axiosBase.post("/gallery/getrejectedimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageUpload = (input: any) => {
    return axiosBase.post("/gallery/uploadimages", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageApproveReject = (input: any) => {
    return axiosBase.post("/gallery/changecategory", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageRemove = (input: any) => {
    return axiosBase.delete("/gallery/deleteimages", { data: input })
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getByFilterEvent = (input: any) => {
    return axiosBase.post("/events/getbyfilter", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

export const getRSVPresponse = (input: any) => {
    const queryString = `?status=${input.status}&eventId=${input.eventId}&email=${input.email}`;
    return axiosBase.get(`/members/rsvp${queryString}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

const getInteraction = (input: any) => {
    return axiosBase.post("/gallery/interaction", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const createCommentOrReply = (input: any) => {
    return axiosBase.post("/commentDiscussion/add", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const removeCommentOrReply = (input: any) => {
    return axiosBase.delete("/commentDiscussion/delete", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getAllComments = (input: any) => {
    return axiosBase.get("/commentDiscussion/getDiscussion/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getAllReplies = (input: any) => {
    return axiosBase.get("/commentDiscussion/getComment/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const getAllReports = (input: any) => {
    return axiosBase.get("/commentDiscussion/reportlist/" + input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const userInteraction = (input: any) => {
    return axiosBase.post("/commentDiscussion/interaction", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const addReport = (input: any) => {
    return axiosBase.post("/commentDiscussion/report", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const updateCommentOrReply = (input: any) => {
    return axiosBase.patch("/commentDiscussion/update", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const textSentimentByMonth = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/textByMonth", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const textSentimentByStats = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/textByStats", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const textSentimentByCategory = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/textByCategory", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageSentimentByMonth = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/imageByMonth", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageSentimentByStats = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/imageByStats", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

const imageSentimentByCategory = (input: any) => {
    return axiosBase.post("/sentimentAnalyse/imageByCategory", input)
        .then((response) => response.data)
        .catch((error) => errorHandler(error));
};

export const eventService = {
    getAllEvents,
    createEvent, getEvent, updateEvent, deleteEvent,
    getUploadedImages, getApprovedImages, getRejectedImages,
    imageUpload, imageApproveReject, imageRemove, getByFilterEvent, getRSVPresponse, getInteraction,
    createCommentOrReply, removeCommentOrReply, getAllComments, getAllReplies, 
    getAllReports, userInteraction, addReport, updateCommentOrReply,
    textSentimentByMonth, textSentimentByStats, textSentimentByCategory,
    imageSentimentByMonth, imageSentimentByStats, imageSentimentByCategory,
}